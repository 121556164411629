import React from "react";
import styled from "styled-components";

const SVGContainer = styled.div`
  width: ${(props) => props.width || "45px"};
  height: ${(props) => props.height || "45px"};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SVGStatic = styled.svg`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 26px;
  height: 26px;

  animation: breathe1 1.2s ease-in-out 1;
  animation-delay: ${(props) => props.delay || 0}ms;

  @keyframes breathe1 {
    25% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const LogoPath = styled.path`
  opacity: 1;
  fill: whitesmoke;
  fill-opacity: 1;
  stroke: whitesmoke;
  stroke-opacity: 0;
`;

const Container = styled.div`
  padding: 6px 6px 6px 8px;
  width: 26px;
  height: 26px;
  box-sizing: content-box;
  border-radius: 7px;
  z-index: 1;
`;

// const Container = styled.div`
//   padding: 6px 6px 6px 8px;
//   width: 26px;
//   height: 26px;
//   box-sizing: content-box;
//   border-radius: 7px;
//   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
//   background: radial-gradient(circle at bottom center, #aa3174 15px, #540096);
// `;

const LoadingEmblem = () => {
  return (
    <Container>
      <SVGContainer className='ubPath'>
        <SVGStatic delay='50' viewBox='0 0 2800 2800'>
          <g id='layer101' transform='translate(-509.15586,-650)'>
            <LogoPath
              d='m 1090,3425 c -138,-31 -249,-92 -345,-189 -88,-89 -100,-104 -142,-184 -52,-98 -78,-188 -89,-310 -10,-101 -10,-100 82,-90 215,23 401,126 529,293 88,115 147,252 160,375 3,30 8,70 11,88 l 5,32 -78,-1 c -43,-1 -103,-7 -133,-14 z'
              id='path0'
            />
          </g>
        </SVGStatic>
        <SVGStatic delay='200' viewBox='0 0 2800 2800'>
          <g id='layer101' transform='translate(-509.15586,-650)'>
            <LogoPath
              d='m 1446,3354 c -31,-413 -353,-771 -765,-849 -42,-8 -97,-15 -123,-15 h -48 v -301 -302 l 113,6 c 246,12 521,106 744,254 393,262 654,713 680,1176 l 6,117 h -300 -300 z'
              id='path1'
            />
          </g>
        </SVGStatic>
        <SVGStatic delay='300' viewBox='0 0 2800 2800'>
          <g id='layer101' transform='translate(-509.15586,-650)'>
            <LogoPath
              d='m 2210,3373 c -1,-235 -102,-587 -226,-788 -13,-22 -27,-47 -31,-55 -10,-21 -102,-148 -138,-190 -82,-94 -127,-139 -205,-204 -105,-88 -137,-111 -225,-163 -148,-86 -189,-105 -340,-158 -129,-45 -349,-84 -472,-85 h -63 v -118 c 0,-145 22,-280 61,-375 52,-125 46,-121 153,-112 50,4 154,19 231,35 340,68 659,213 950,433 71,54 336,310 375,362 8,11 31,41 51,65 89,112 205,308 287,486 93,204 165,493 188,755 l 7,77 -69,32 c -118,55 -199,70 -376,70 h -158 z'
              id='path2'
            />
          </g>
        </SVGStatic>
        <SVGStatic delay='400' viewBox='0 0 2800 2800'>
          <g id='layer101' transform='translate(-509.15586,-650)'>
            <LogoPath
              d='m 2957,3196 c -4,-10 -7,-32 -7,-49 0,-18 -7,-70 -15,-117 -14,-81 -18,-100 -46,-215 -35,-148 -105,-329 -199,-517 C 2493,1906 2124,1517 1740,1297 1479,1148 1166,1034 905,995 845,985 788,976 780,973 741,961 932,849 1053,812 c 42,-12 127,-16 445,-19 l 394,-5 116,68 c 108,62 350,227 397,270 11,11 45,40 75,66 238,203 462,477 636,778 l 49,85 v 375 c 0,281 -3,390 -14,435 -29,130 -176,379 -194,331 z'
              id='path3'
            />
          </g>
        </SVGStatic>
        <SVGStatic delay='500' viewBox='0 0 2800 2800'>
          <g id='layer101' transform='translate(-509.15586,-650)'>
            <LogoPath
              d='m 3147,1721 c -25,-43 -178,-244 -238,-314 -128,-148 -342,-353 -464,-442 -22,-17 -42,-32 -45,-35 -4,-5 -106,-78 -177,-128 -15,-10 15,-12 167,-8 167,3 193,6 263,30 95,32 146,57 212,106 137,101 227,229 275,390 18,61 23,108 28,253 3,97 3,177 1,177 -3,0 -12,-13 -22,-29 z'
              id='path4'
            />
          </g>
        </SVGStatic>
      </SVGContainer>
    </Container>
  );
};

export default LoadingEmblem;
