import * as React from "react";

import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import Landing from "../components/home-page/landing";
import HomePageAnimation from "../components/home-page/home-page-animation";
import SectorInformation from "../components/home-page/sector-information";

const Root = styled.main`
  padding: 0px;
  width: 100vw;
  background: white;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const IndexPage = (props) => {
  return (
    <Root>
      <Landing images={props.data} />
      <HomePageAnimation />
      <SectorInformation images={props.data} />
    </Root>
  );
};

export default IndexPage;

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Home - UtterBerry</title>
      <meta
        name='description'
        content='Home Page - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export const pageQuery = graphql`
  query {
    topRight: file(
      relativePath: { eq: "utterberry-paints/top-right-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 210, height: 103)
      }
    }
    topLeft: file(
      relativePath: { eq: "utterberry-paints/top-left-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 215, height: 115)
      }
    }
    middleRight: file(
      relativePath: { eq: "utterberry-paints/middle-right-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 190, height: 120)
      }
    }
    middleLeft: file(
      relativePath: { eq: "utterberry-paints/middle-left-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 215, height: 132)
      }
    }
    bottomRight: file(
      relativePath: { eq: "utterberry-paints/bottom-right-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 200, height: 96)
      }
    }
    bottomLeft: file(
      relativePath: { eq: "utterberry-paints/bottom-left-paint.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 200, height: 88)
      }
    }
    agriculture: file(relativePath: { eq: "sector-images/Agriculture.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 100, height: 92)
      }
    }
    industry: file(relativePath: { eq: "sector-images/Industry.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    security: file(relativePath: { eq: "sector-images/Security.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    tunnels: file(relativePath: { eq: "sector-images/Tunnels.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    construction: file(
      relativePath: { eq: "sector-images/Construction.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    roads: file(relativePath: { eq: "sector-images/Roads.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    smartDevices: file(
      relativePath: { eq: "sector-images/Smart-Devices.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    sports: file(relativePath: { eq: "sector-images/Sports.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    buildings: file(relativePath: { eq: "sector-images/Buildings.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    crowds: file(relativePath: { eq: "sector-images/Crowds.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    train: file(relativePath: { eq: "sector-images/Train.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    bridges: file(relativePath: { eq: "sector-images/Bridges.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    underground: file(relativePath: { eq: "sector-images/Underground.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
    hospital: file(relativePath: { eq: "sector-images/Hospital.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 85, height: 85)
      }
    }
  }
`;
