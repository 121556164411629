import React, { useEffect, useState, useRef } from "react";

import { styled } from "@mui/material/styles";

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import { Typography, Grow, Fade, useMediaQuery } from "@mui/material";

import LoadingEmblem from "../loading-emblem";
import { useTrail, a, useSpring } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

import BranchBackground from "../../images/branches/branch-background-3.svg";
import WhiteBranchBackground from "../../images/branches/branch-background-1.svg";

import BranchOutLeft from "../../images/branches/left-brand.svg";
import BranchOutRight from "../../images/branches/right-brand.svg";

const PREFIX = "Landing";

const classes = {
  bgImage: `${PREFIX}-bgImage`,
  root: `${PREFIX}-root`,
  overlay: `${PREFIX}-overlay`,
  clipParent: `${PREFIX}-clipParent`,
  secondClipParent: `${PREFIX}-secondClipParent`,
  scaleParent: `${PREFIX}-scaleParent`,
  fixedContent: `${PREFIX}-fixedContent`,
  content: `${PREFIX}-content`,
  observerContent: `${PREFIX}-observerContent`,
  innerContent: `${PREFIX}-innerContent`,
  ubAndDetail: `${PREFIX}-ubAndDetail`,
  ubTitle: `${PREFIX}-ubTitle`,
  detail: `${PREFIX}-detail`,
  mouseContainer: `${PREFIX}-mouseContainer`,
  image: `${PREFIX}-image`,
  contentInfo: `${PREFIX}-contentInfo`,
  titleContainer: `${PREFIX}-titleContainer`,
  sensorBackground: `${PREFIX}-sensorBackground`,
  topBranch: `${PREFIX}-topBranch`,
  bottomBranch: `${PREFIX}-bottomBranch`,
  branchContainer: `${PREFIX}-branchContainer`,
  infoContainer: `${PREFIX}-infoContainer`,
  paintBackground: `${PREFIX}-paintBackground`,
  branchOutLeft: `${PREFIX}-branchOutLeft`,
  branchOutRight: `${PREFIX}-branchOutRight`,
  animatedDescription: `${PREFIX}-animatedDescription`,
  animatedPaint: `${PREFIX}-animatedPaint`,
  scrollArrow: `${PREFIX}-scrollArrow`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  [`& .${classes.bgImage}`]: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    pointerEvents: "none",
  },

  [`& .${classes.root}`]: {
    height: "calc(100vh - 65px)",
    width: "100vw",
    position: "relative",
    overflow: "hidden",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 57px)",
    },
    "@media (max-height: 500px)": {
      height: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.overlay}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#540096",
    opacity: 0.55,
    cursor: "pointer",
  },

  [`& .${classes.clipParent}`]: {
    clip: "auto",
    width: "100%",
    height: "calc(100vh - 65px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 2,
    WebkitMaskImage: "-webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%)",
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 57px)",
    },
    "@media (max-height: 500px)": {
      height: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.secondClipParent}`]: {
    clip: "auto",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    opacity: 0,
    transition: "0.5s",
    pointerEvents: "none",
    WebkitMaskImage: "-webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%)",
  },

  [`& .${classes.scaleParent}`]: {
    width: "100%",
    height: "calc(100vh - 65px)",
    position: "relative",
    left: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 57px)",
    },
    "@media (max-height: 500px)": {
      height: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.fixedContent}`]: {
    width: "100%",
    height: "calc(100vh - 65px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 65,
    willChange: "transform",
    transform: "scale(1)",
    transition: "0.05s",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 57px)",
    },
    "@media (max-height: 500px)": {
      height: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "calc(100vh - 65px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 65,
    willChange: "transform",
    transform: "scale(1)",
    transition: "0.05s",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 57px)",
    },
    "@media (max-height: 500px)": {
      height: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.observerContent}`]: {
    width: "100%",
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
    padding: "50px 0px 0px",
  },

  [`& .${classes.innerContent}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "scale(1)",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      transform: "scale(1.0)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "scale(1.45)",
    },
    [theme.breakpoints.between("md", "lg")]: {
      transform: "scale(1.7)",
    },
    [theme.breakpoints.up("lg")]: {
      transform: "scale(1.8)",
    },
  },

  [`& .${classes.ubAndDetail}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 1,
  },

  [`& .${classes.ubTitle}`]: {
    width: 115,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.detail}`]: {
    color: "white",
  },

  [`& .${classes.mouseContainer}`]: {
    position: "absolute",
    bottom: "140px",
    left: "50%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      bottom: "100px",
      transform: "translateX(-50%) scale(0.6)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: "140px",
      transform: "translateX(-50%) scale(0.8)",
    },
    "@media (max-height: 600px) and (max-width: 600px)": {
      bottom: "100px",
      transform: "translateX(-50%) scale(0.6)",
    },
    "@media (max-height: 700px) and (min-width: 600px)": {
      bottom: "100px",
      display: "none",
      transform: "translateX(-50%) scale(0.6)",
    },
    "@media (max-height: 750px) and (min-width: 900px)": {
      bottom: "100px",
      display: "none",
      transform: "translateX(-50%) scale(0.6)",
    },
  },

  [`& .${classes.image}`]: {
    maxWidth: 1000,
    width: "100%",
  },

  [`& .${classes.contentInfo}`]: {
    display: "flex",
    justifyContent: "center",
    margin: "25px 0px",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
    zIndex: 0,
  },

  [`& .${classes.titleContainer}`]: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderBottom: "1px solid #540096",
    marginBottom: 10,
  },

  [`& .${classes.sensorBackground}`]: {
    width: 250,
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 0,
    objectFit: "fill",
    transform: "translate(-48%, -43%)",
    [theme.breakpoints.down("sm")]: {
      width: 230,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 330,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 380,
    },
    [theme.breakpoints.up("lg")]: {
      width: 415,
    },
  },

  [`& .${classes.topBranch}`]: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    height: 140,
    position: "absolute",
    left: 0,
    top: "calc(100vh - 65px)",
    backgroundSize: "500px 170px",
    backgroundRepeat: "repeat-x",
    backgroundPosition: "top",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "280px 100px",
      top: "calc(100vh - 57px)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundSize: "350px 125px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      backgroundSize: "420px 145px",
    },
    "@media (max-height: 500px)": {
      top: "calc(100vh - 49px)",
    },
  },

  [`& .${classes.bottomBranch}`]: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    height: 180,
    zIndex: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundSize: "500px 170px",
    backgroundPosition: "bottom ",
    backgroundRepeat: "repeat-x",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "280px 100px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundSize: "350px 125px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      backgroundSize: "420px 145px",
    },
  },

  [`& .${classes.branchContainer}`]: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    overflow: "hidden",
  },

  // animated information section
  [`& .${classes.infoContainer}`]: {
    flexGrow: 1,
    width: "100%",
    padding: "130px 40px 40px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr",
    zIndex: 10,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 4px 4px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "60px 10px 10px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "130px 20px 20px",
    },
    "@media(max-width:680px)": {
      gridTemplateColumns: "1fr ",
      gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr",
    },
  },

  [`& .${classes.paintBackground}`]: {
    width: 390,
    height: 215,
    [theme.breakpoints.down("sm")]: {
      width: 380,
      height: 225,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 340,
      height: 235,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 430,
      height: 265,
    },
    [theme.breakpoints.up("lg")]: {
      width: 485,
      height: 285,
    },
    "@media(max-width:420px)": {
      width: 320,
      height: 200,
    },
    "@media(max-width:680px)": {
      width: 380,
      height: 225,
    },
  },

  //branches left and right
  [`& .${classes.branchOutLeft}`]: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: -1,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 120,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 140,
    },
  },

  [`& .${classes.branchOutRight}`]: {
    position: "absolute",
    right: -7,
    bottom: 0,
    zIndex: -1,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 120,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 140,
    },
  },

  [`& .${classes.animatedDescription}`]: {
    minWidth: 250,
    textAlign: "center",
    color: "white",
    // textShadow:
    //   "-1px -1px 0 rgba(231,197,246,0.7), 1px -1px 0 rgba(231,197,246,0.7), -1px 1px 0 rgba(231,197,246,0.7), 1px 1px 0 rgba(231,197,246,0.7)",
    [theme.breakpoints.up("md")]: {
      minWidth: 300,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 380,
    },
  },

  [`& .${classes.animatedPaint}`]: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 150,
    transition: "0.4s",
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 250,
    },
  },

  [`& .${classes.scrollArrow}`]: {
    position: "absolute",
    width: "24px",
    height: "24px",
    borderLeft: "1px solid #fff",
    borderBottom: "1px solid #fff",
    transform: "rotate(-45deg)",
    animation: "scrollDown 2s infinite",
    opacity: 0,
    boxSizing: "border-box",
    left: "8px",
  },
}));

const ScrollButton = (props) => {
  return (
    <button
      onClick={() => {
        let target = document.getElementById("observer");

        window.scrollTo({
          top: target.offsetTop,
          behavior: "smooth",
        });
      }}
      aria-label='Scroll Down'
      style={{
        width: "40px",
        height: "70px",
        background: "none",
        border: "none",
        position: "relative",
        display: "block",
      }}
    >
      <span
        className={classes.scrollArrow}
        style={{
          top: "0px",
        }}
      ></span>
      <span
        className={classes.scrollArrow}
        style={{
          top: "16px",
          animationDelay: "0.15s",
        }}
      ></span>
      <span
        className={classes.scrollArrow}
        style={{
          top: "32px",
          animationDelay: "0.3s",
        }}
      ></span>
    </button>
  );
};

const BranchRow = (props) => {
  const [styles, api] = useSpring(() => ({
    height: 0,
    config: { duration: 1500 },
  }));

  useEffect(() => {
    api.start({
      height: 160,
    });
  }, [api]);

  return (
    <a.div
      className={classes.topBranch}
      style={{
        backgroundImage: `url("${BranchBackground}")`,
        ...styles,
      }}
    ></a.div>
  );
};

const WhiteBranchRow = (props) => {
  const [styles, api] = useSpring(() => ({
    height: 0,
    config: { duration: 1500 },
  }));

  useEffect(() => {
    setTimeout(() => {
      api.start({
        height: 200,
      });
    }, 500);
  }, [api]);

  return (
    <a.div
      className={classes.branchContainer}
      style={{
        ...styles,
        zIndex: 0,
        marginBottom: -5,
        WebkitMaskImage:
          "-webkit-gradient(linear, 0% 30%, 0% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
      }}
    >
      <div
        className={classes.bottomBranch}
        style={{
          backgroundImage: `url("${WhiteBranchBackground}")`,
        }}
      ></div>
    </a.div>
  );
};

const cards = [
  {
    description:
      "UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.",
    delay: 0,
    color: "pink",
    position: "center",
    smallPosition: "start",
    image: "topLeft",
  },
  {
    description:
      "The UtterBerry System consists of a collection of miniature, artificially intelligent, ultra-low powered sensors, all capable of monitoring the environment around them.",
    delay: 400,
    color: "orange",
    position: "center",
    smallPosition: "end",
    image: "TopRight",
  },
  {
    description:
      "For real-time remote access, the sensors are accompanied by a separate Base Station to provide connectivity with the Internet or Local Network. This information is then accessed through our secure online portal, UtterBerry.io.",
    delay: 800,
    color: "red",
    position: "start",
    smallPosition: "start",
    image: "MiddleLeft",
    padding: "0px 0px 10px 0px",
  },
  {
    description:
      "The UtterBerry system has been implemented in a wide variety of sectors such as tunnelling, medicine, events, security, and construction.",
    delay: 1200,
    color: "green",
    position: "end",
    smallPosition: "end",
    image: "MiddleRight",
    padding: "20px 0px 0px 0px",
  },
  {
    description:
      "The sensors uniquely small physical presence allows for refined and subtle monitoring options while maintain sub-millimetre accuracy.",
    delay: 1600,
    color: "blue",
    position: "center",
    smallPosition: "start",
    image: "BottomLeft",
  },
  {
    description:
      "These wireless sensors self-calibrate to form a mesh network and relay data between each other. They do not require line of sight between each other to maintain efficiency and accuracy.",
    delay: 2000,
    color: "purple",
    position: "center",
    smallPosition: "end",
    image: "BottomRight",
  },
];

const AnimatedCard = ({
  description,
  position,
  smallPosition,
  image,
  inView,
  delay,
  padding,
}) => {
  const matches = useMediaQuery("(max-width:680px)");

  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    let animate = () => {
      setAnimateIn(true);
    };

    if (inView) {
      setTimeout(animate, delay);
    }

    return () => {
      if (inView) {
        clearTimeout(animate);
      }
    };
  }, [inView, delay]);

  const gImage = getImage(image);

  return (
    <div
      style={{
        justifySelf: matches ? smallPosition : position,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grow in={animateIn}>
        <div>
          <GatsbyImage
            image={gImage}
            className={classes.paintBackground}
            imgStyle={{
              transform: "scale(1.01)",
            }}
            objectFit={"contain"}
            alt={"Paint Background"}
            width={485}
            height={285}
          />
        </div>
      </Grow>
      <div
        className={classes.animatedPaint}
        style={{
          opacity: animateIn ? 1 : 0,
          padding: padding || "0px",
        }}
      >
        <Typography className={classes.animatedDescription}>
          {description}
        </Typography>
      </div>
    </div>
  );
};

const AnimatedInformation = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div className={classes.infoContainer} ref={ref}>
      <AnimatedCard
        description={cards[0].description}
        color={cards[0].color}
        position={cards[0].position}
        smallPosition={cards[0].smallPosition}
        image={props.images.topLeft}
        inView={inView}
        delay={cards[0].delay}
        padding={cards[0].padding}
      />
      <AnimatedCard
        description={cards[1].description}
        color={cards[1].color}
        position={cards[1].position}
        smallPosition={cards[1].smallPosition}
        image={props.images.topRight}
        inView={inView}
        delay={cards[1].delay}
        padding={cards[1].padding}
      />
      <AnimatedCard
        description={cards[2].description}
        color={cards[2].color}
        position={cards[2].position}
        smallPosition={cards[2].smallPosition}
        image={props.images.middleLeft}
        inView={inView}
        delay={cards[2].delay}
        padding={cards[2].padding}
      />
      <AnimatedCard
        description={cards[3].description}
        color={cards[3].color}
        position={cards[3].position}
        smallPosition={cards[3].smallPosition}
        image={props.images.middleRight}
        inView={inView}
        delay={cards[3].delay}
        padding={cards[3].padding}
      />
      <AnimatedCard
        description={cards[4].description}
        color={cards[4].color}
        position={cards[4].position}
        smallPosition={cards[4].smallPosition}
        image={props.images.bottomLeft}
        inView={inView}
        delay={cards[4].delay}
        padding={cards[4].padding}
      />
      <AnimatedCard
        description={cards[5].description}
        color={cards[5].color}
        position={cards[5].position}
        smallPosition={cards[5].smallPosition}
        image={props.images.bottomRight}
        inView={inView}
        delay={cards[5].delay}
        padding={cards[5].padding}
      />
    </div>
  );
};

const Landing = (props) => {
  const [loadIn, setLoadIn] = useState(false);

  const trail = useTrail(1, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: loadIn ? 1 : 0,
    x: loadIn ? 0 : 40,
    width: loadIn ? 115 : 0,
    from: { opacity: 0, x: 40, height: 0 },
  });

  // images loaded
  const [bLoaded, setBLoaded] = useState(true);

  const blackUtterBerryRef = useRef();
  const brandUtterBerryRef = useRef();
  const observerRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoadIn(true);
    }, 400);
  }, []);

  useEffect(() => {
    function handleIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.boundingClientRect.y > 0) {
          blackUtterBerryRef.current.style.transform = `scale(${
            1 - entry.intersectionRatio * 0.4
          })`;
          brandUtterBerryRef.current.style.transform = `scale(${
            1 - entry.intersectionRatio * 0.4
          })`;
        }
      });
    }

    function buildThresholdList() {
      let thresholds = [0];
      let numSteps = 100;

      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }

      return thresholds;
    }

    if (
      observerRef.current &&
      blackUtterBerryRef.current &&
      brandUtterBerryRef.current
    ) {
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: buildThresholdList(),
      };

      let observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(observerRef.current);
    }
  }, []);

  return (
    <Root>
      <div className={classes.root}>
        <StaticImage
          src={"../../images/background-min.webp"}
          className={classes.bgImage}
          objectFit={"cover"}
          placeholder={"#540096"}
          layout={"fullWidth"}
          alt={"Background"}
          loading={"eager"}
        />
        <div
          className={classes.overlay}
          onClick={() => {
            let target = document.getElementById("observer");
            let offsetDistance;

            if (target.clientWidth < 680) {
              offsetDistance = target.offsetTop;
            } else if (target.clientWidth < 900) {
              offsetDistance = offsetDistance - 20;
            } else {
              offsetDistance = offsetDistance + 100;
            }

            window.scrollTo({
              top: offsetDistance,
              behavior: "smooth",
            });
          }}
        ></div>
        <div className={classes.mouseContainer}>
          <ScrollButton />
        </div>
        <div className={classes.clipParent}>
          <Fade in={bLoaded}>
            <div className={classes.fixedContent} ref={blackUtterBerryRef}>
              {bLoaded && (
                <div className={classes.innerContent}>
                  <LoadingEmblem />
                  <div className={classes.ubAndDetail}>
                    {trail.map(({ height, width, ...style }, index) => (
                      <a.div
                        key={index}
                        style={{
                          ...style,
                        }}
                      >
                        {index === 0 && (
                          <a.div style={{ width, overflow: "hidden" }}>
                            <StaticImage
                              src={"../../images/utterberry-title-white.webp"}
                              className={classes.ubTitle}
                              objectFit={"contain"}
                              placeholder={"blurred"}
                              alt={"UtterBerry"}
                              loading={"eager"}
                              width={207}
                              height={57}
                            />
                          </a.div>
                        )}
                      </a.div>
                    ))}
                  </div>
                </div>
              )}
              <StaticImage
                src={"../../images/sensor-images/sensor-background-1.webp"}
                className={classes.sensorBackground}
                objectFit={"contain"}
                placeholder={"white"}
                loading={"eager"}
                alt={"UtterBerry"}
                width={415}
                height={234}
                onLoad={() => {
                  setBLoaded(true);
                }}
              />
            </div>
          </Fade>
        </div>
        <WhiteBranchRow />
      </div>
      <BranchRow />
      <div
        className={classes.secondClipParent}
        style={{
          opacity: bLoaded ? 1 : 0,
        }}
      >
        <div className={classes.content} ref={brandUtterBerryRef}>
          <div className={classes.innerContent}>
            <LoadingEmblem />
            <div className={classes.ubAndDetail}>
              <div
                style={{
                  overflow: "hidden",
                  width: 115,
                }}
              >
                <StaticImage
                  src={"../../images/utterberry-title-white.webp"}
                  className={classes.ubTitle}
                  objectFit={"contain"}
                  placeholder={"none"}
                  alt={"UtterBerry"}
                  loading={"eager"}
                  width={207}
                  height={57}
                />
              </div>
            </div>
          </div>
          <StaticImage
            src={"../../images/sensor-images/sensor-background-3.webp"}
            className={classes.sensorBackground}
            objectFit={"contain"}
            loading={"eager"}
            placeholder={"none"}
            alt={"UtterBerry"}
            width={415}
            height={234}
          />
        </div>
      </div>
      <div
        ref={observerRef}
        className={classes.observerContent}
        id={"observer"}
      >
        <AnimatedInformation images={props.images} />
        <img
          src={BranchOutLeft}
          className={classes.branchOutLeft}
          alt={"Track Branch Left"}
        />
        <img
          src={BranchOutRight}
          className={classes.branchOutRight}
          alt={"Track Branch Right"}
        />
      </div>
    </Root>
  );
};

export default Landing;
