// png sector images
// import AgricultureImageBackup from "../images/sector-images/Agriculture.png";
// import IndustryImageBackup from "../images/sector-images/Industry.png";
// import SecurityImageBackup from "../images/sector-images/Security.png";
// import TunnelsImageBackup from "../images/sector-images/Tunnels.png";
// import ConstructionImageBackup from "../images/sector-images/Construction.png";
// import RoadsImageBackup from "../images/sector-images/Roads.png";
// import SmartDevicesImageBackup from "../images/sector-images/Smart Devices.png";
// import SportsImageBackup from "../images/sector-images/Sports.png";
// import BuildingsImageBackup from "../images/sector-images/Buildings.png";
// import CrowdsImageBackup from "../images/sector-images/Crowds.png";
// import TrainImageBackup from "../images/sector-images/Train.png";
// import BridgesImageBackup from "../images/sector-images/Bridges.png";
// import UndergroundImageBackup from "../images/sector-images/Underground.png";
// import HospitalImageBackup from "../images/sector-images/Hospital.png";

// webp sector images
import AgricultureImage from "../images/sector-images/Agriculture.webp";
import IndustryImage from "../images/sector-images/Industry.webp";
import SecurityImage from "../images/sector-images/Security.webp";
import TunnelsImage from "../images/sector-images/Tunnels.webp";
import ConstructionImage from "../images/sector-images/Construction.webp";
import RoadsImage from "../images/sector-images/Roads.webp";
import SmartDevicesImage from "../images/sector-images/Smart-Devices.webp";
import SportsImage from "../images/sector-images/Sports.webp";
import BuildingsImage from "../images/sector-images/Buildings.webp";
import CrowdsImage from "../images/sector-images/Crowds.webp";
import TrainImage from "../images/sector-images/Train.webp";
import BridgesImage from "../images/sector-images/Bridges.webp";
import UndergroundImage from "../images/sector-images/Underground.webp";
import HospitalImage from "../images/sector-images/Hospital.webp";

// sector videos
import AgricultureTimeLapse from "../videos/sectors-compressed/agriculture_480.mp4";
import BridgeTimeLapse from "../videos/sectors-compressed/bridge_480.mp4";
import BuildingTimeLapse from "../videos/sectors-compressed/building_480.mp4";
import ConstructionTimeLapse from "../videos/sectors-compressed/construction_480.mp4";
import CrowdsTimeLapse from "../videos/sectors-compressed/crowds_480.mp4";
import HospitalTimeLapse from "../videos/sectors-compressed/hospitals_480.mp4";
import IndustryTimeLapse from "../videos/sectors-compressed/industry_480.mp4";
import RoadTimeLapse from "../videos/sectors-compressed/road_480.mp4";
import SecurityTimeLapse from "../videos/sectors-compressed/security_480.mp4";
import SmartDevicesTimeLapse from "../videos/sectors-compressed/smart-devices_480.mp4";
import SportsTimeLapse from "../videos/sectors-compressed/sports_480.mp4";
import TrainsTimeLapse from "../videos/sectors-compressed/trains_480.mp4";
import TunnelTimeLapse from "../videos/sectors-compressed/tunnel_480.mp4";
import UndergroundTimeLapse from "../videos/sectors-compressed/underground_480.mp4";

const sectors = [
  {
    image: AgricultureImage,
    title: "Agriculture",
    timelapse: AgricultureTimeLapse,
    description: `The agricultural industry can be uniquely assisted by the installation of the UtterBerry system. The technology can monitor and control activity throughout agricultural buildings, fields and other infrastructure. The system can monitor the usage of lighting, water, energy, feed, and all other agriculturally expended resources, allowing for more efficient practice throughout the agricultural process. Additionally, the system can differentiate between livestock and humans, providing both personnel and animal location sensing capabilities. The sensors can monitor all aspects of an agricultural system in order to alert the relevant personnel of any errors, dangers or other issues that may be occurring, and by utilising the sensors on board artificial intelligence and machine learning the system can predictively anticipate potential issues and issue pre-emptive warnings.`,
  },
  {
    image: IndustryImage,
    title: "Industry",
    timelapse: IndustryTimeLapse,
    description: `The UtterBerry system can be installed throughout industrial sites to monitor entire complexes to varying degrees. The sensors system can be used to monitor the buildings structural health, sending alerts to relevant personnel to any issues that may need to be dealt with. Similarly the system can monitor crowd density and movement, allowing for a convergence of data that can improve overall site safety drastically. Combined with the sensors onboard artificial intelligence the system can send out predictive alerts and learn over time how to maintain high accuracy. Finally the sensor system can be used to discreetly and safely monitor emissions levels. This data can be used to remedy errors and remain informed about all aspects of an industrial site.`,
  },
  {
    image: SecurityImage,
    title: "Security",
    timelapse: SecurityTimeLapse,
    description: `UtterBerry technology can be used to improve and maintain security measures in a plethora of circumstances. Not only can the sensors be installed to monitor any entrance for intruders, it can also be linked with a camera system for rapid, secure, and anonymous facial recognition. The artificial intelligence and machine learning that is onboard UtterBerry sensors can be applied to security systems, allowing for a level of learning to occur over time. The data that the system collects is equally as secure, as with all other UtterBerry systems, all of the data that is anonymously collected is stored within the patented UtterBerry blockchain, keeping it extremely secure at all times.`,
  },
  {
    image: TunnelsImage,
    title: "Tunnels",
    timelapse: TunnelTimeLapse,
    description: ` UtterBerry sensors can be installed throughout a tunnel or a network of tunnels to fully monitor structural integrity. The system can detect minute tilt and displacement within the entire tunnels structure, allowing for remote monitoring of the tunnel and as a result any unsafe trips by personnel into the tunnel are not needed. The system is also capable of detecting leaks within the tunnel to send alerts if the tunnel is breached in any way. The sensors onboard artificial intelligence and machine learning allow for the UtterBerry sensors to communicate and give predictive warnings about potential tilt, displacement or leaking in order to preserve the safety of those working in the tunnel and the tunnel itself.`,
  },
  {
    image: ConstructionImage,
    title: "Construction",
    timelapse: ConstructionTimeLapse,
    description: `UtterBerry sensors can be placed throughout both large and small construction sites to create a network that monitors the site in its entirety. The sensor system can communicate and, with the help of onboard artificial intelligence and machine learning, give predictive and reactive alerts if any unplanned changes occur anywhere on the construction site. The system can also collect data on worker density on site allowing for the alerts given by the system to be even further informed by the density of workers in the affected area. The UtterBerry system is accurate to a sub-millimetre level and can communicate without line of sight, allowing for full site coverage that increases worker safety and improves site management.`,
  },
  {
    image: RoadsImage,
    title: "Roads",
    timelapse: RoadTimeLapse,
    description: `The UtterBerry technology can monitor entire road infrastructure areas to provide accurate road usage statistics, allowing for advanced adaptive real-time road traffic control via integration with traffic lights, speed limiting and diversion handling. If the UtterBerry Sensor System detects an issue anywhere throughout the monitored area of the road infrastructure, or if the sensors utilise the onboard artificial intelligence and machine learning to predict an issue, the relevant personnel will be notified as to what the issue is and where it is taking place. The sensors can also be used to implement energy saving. When combined with street lighting the sensors can be used to ensure that lamps are only turned on while moving vehicles are in the vicinity.`,
  },
  {
    image: SmartDevicesImage,
    title: "Smart Devices",
    timelapse: SmartDevicesTimeLapse,
    description: `The unique artificial intelligence, machine learning and communicative assets that the UtterBerry technology can provide can be amplified by integrating it into smart devices. `,
  },
  {
    image: SportsImage,
    title: "Sports",
    timelapse: SportsTimeLapse,
    description: `UtterBerry technology has two unique applications within the sports sector. The first is biometric monitoring for training. The UtterBerry sensors, when specifically applied for sports training purposes, can monitor intricate biometrics and physical movements to a very fine degree. The data that the system gathers can be used to vastly improve how to evolve the training needed and can also give insights into injury manifestation and recovery. The second UtterBerry application within sports is within events and stadiums. `,
  },
  {
    image: BuildingsImage,
    title: "Buildings",
    timelapse: BuildingTimeLapse,
    description: `The UtterBerry system can be installed throughout new or existing buildings, allowing the system to collect data and monitor the building in its entirety. This allows the system to monitor and tilt, displacement, or movement that may be unknown or unsafe. The sensor system can use its onboard artificial intelligence and machine learning to send predictive and reactive warnings if any unplanned occurrences, building-wide, are happening. As additional data to support this the UtterBerry system can also be used to monitor crowd density within the building and its floors. With this information that system can maintain security and safety within buildings. Finally, the UtterBerry system can be used to monitor the use of lighting and energy within the building to procure and assist in maximum efficiency.`,
  },
  {
    image: CrowdsImage,
    title: "Crowds",
    timelapse: CrowdsTimeLapse,
    description: `UtterBerry technology can be used to monitor crowds throughout both small and large venues. Wether it be a large scale event or a small area that requires intricate monitoring, the UtterBerry sensor system can create a detailed network for fully monitoring large crowds, their density and their movement. The system can be added to a camera network to allow for even more accurate monitoring and the potential for facial recognition.`,
  },
  {
    image: TrainImage,
    title: "Trains",
    timelapse: TrainsTimeLapse,
    description: `The UtterBerry Smart Sensor System can improve the technology both within their trains and throughout the networks infrastructure. Throughout stations and trains the UtterBerry system can improve the workflows and the travel experience of their passengers, with real-time monitoring of passenger numbers within train carriages allowing for increasingly accurate travel information for passengers, allowing the relevant personnel to view both train carriage and platform crowd densities. The UtterBerry sensors can also be integrated into the network’s infrastructure. By installing sensors throughout the rail network the system can both predictively and reactively send alerts and warnings for any major or minor issue occurring throughout the network. This information - including the source of the issue, the location of the issue, and the effects the issue may have on the broader rail network – is sent directly to all those who require the urgent information.`,
  },
  {
    image: BridgesImage,
    title: "Bridges",
    timelapse: BridgeTimeLapse,
    description: `The UtterBerry system, when installed to monitor a bridge, can supply a large amount of information and data. First, the sensor system can detect the number of people crossing the bridge, along with the ability to differentiate between various potential pedestrians. The sensors can also monitor the number, weight and kind of vehicle that is crossing the bridge to a high level of accuracy. Along with the ability to monitor all entities crossing the bridge, the UtterBerry system can monitor the bridges own structural health, measuring and tilt, displacement, cracking or shifting to a sub-millimetre level. Thanks to the sensors onboard artificial intelligence and machine learning the UtterBerry system can give predictive warnings as well as reactive alerts to any structural issues with the bridge as well as any safety concerns for those passing over the bridge.`,
  },
  {
    image: UndergroundImage,
    title: "Underground",
    timelapse: UndergroundTimeLapse,
    description: `UtterBerry’s adept application within both train infrastructure and tunnel infrastructure again lends itself to underground transport systems. The sensors can be installed to monitor the tunnels infrastructure itself, warning relevant personnel if any tilt, displacement or leaks occur that might affect the safety of passengers and staff alike. The technology can also be installed on platforms to accurately monitor the density of crowds on the platform area as well as the environment, such as temperature, humidity, air purity and more. Finally the sensor system can be used, when installed on the underground trains, to show carriage density for both staff and passengers, allowing staff to act reactively and passengers to organise for their own convenience.`,
  },
  {
    image: HospitalImage,
    title: "Hospital",
    timelapse: HospitalTimeLapse,
    description: `UtterBerry technology can be integrated into hospitals in two major ways. First, the sensor system can be utilised within new or existing hospitals, creating a new generation of smart-enabled hospitals. The sensor system can monitor and control activity throughout a hospital – including the usage of lighting, water, energy, elevators, access control, security, and fire systems – all of which integrate with staff and patient density and location sensing capabilities. The system can alert relevant personnel of any issues throughout the hospital, as well as providing informative data on who and where is specifically affected and the ramifications these issues can have throughout the rest of the hospital complex. Thanks to the UtterBerry sensor’s onboard artificial intelligence and machine learning these warnings can also be given predictively, allowing for more advanced warning. The second integration is UtterBerry’s patented medical technology. Led by our Multi-Symptom Scanner UtterBerry is committed to creating novel medical technology that can be integrated into smart hospitals.`,
  },
];

export default sectors;
