import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player/lazy";

import { ButtonBase, Typography, Tooltip, IconButton } from "@mui/material";
import { useTransition, animated, useTrail } from "@react-spring/web";
import sectors from "../sectors";
import { useInView } from "react-intersection-observer";
import { ArrowForwardIos } from "@mui/icons-material";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const PREFIX = "SectorInformation";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  windowContainer: `${PREFIX}-windowContainer`,
  root: `${PREFIX}-root`,
  reactPlayer: `${PREFIX}-reactPlayer`,
  sectorButtonImage: `${PREFIX}-sectorButtonImage`,
  sectorButton: `${PREFIX}-sectorButton`,
  detailContainer: `${PREFIX}-detailContainer`,
  header: `${PREFIX}-header`,
  sectorButtonContainer: `${PREFIX}-sectorButtonContainer`,
  sectorButtonContainerSelected: `${PREFIX}-sectorButtonContainerSelected`,
  sectorContainer: `${PREFIX}-sectorContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
  hexagonLeft: `${PREFIX}-hexagonLeft`,
  hexagonRight: `${PREFIX}-hexagonRight`,
  hexagonBottomRight: `${PREFIX}-hexagonBottomRight`,
  titleAndDetails: `${PREFIX}-titleAndDetails`,
  sectorParagraph: `${PREFIX}-sectorParagraph`,
  videoContainer: `${PREFIX}-videoContainer`,
  arrowButtonContainer: `${PREFIX}-arrowButtonContainer`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  overflow: "hidden",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
  zIndex: 0,
  [`& .${classes.windowContainer}`]: {
    width: "100vw",
    overflow: "hidden",
    display: "flex",
    zIndex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    clip: "rect(0, auto, auto, 0)",
    paddingTop: 30,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },

  [`& .${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "40px 25px 25px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "30px 15px 25px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 10px 15px 10px",
    },
  },

  [`& .${classes.reactPlayer}`]: {
    maxWidth: 600,
    width: "100%",
    height: "100%",
  },

  [`& .${classes.sectorButtonImage}`]: {
    width: 85,
    height: 85,
    [theme.breakpoints.down("md")]: {
      width: 55,
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },

  [`& .${classes.sectorButton}`]: {
    padding: 8,
    [theme.breakpoints.down("md")]: {
      padding: 6,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 3,
    },
  },

  [`& .${classes.detailContainer}`]: {
    flexGrow: 1,
    maxWidth: 600,
  },

  [`& .${classes.header}`]: {
    textAlign: "center",
    color: "#540096",
    fontWeight: "bold",
  },

  [`& .${classes.sectorButtonContainer}`]: {
    margin: 10,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      margin: 7,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 4,
    },
  },

  [`& .${classes.sectorButtonContainerSelected}`]: {
    margin: 10,
    boxShadow: "0px 0px 33px -1px rgba(84,0,150,1)",
    borderRadius: "50%",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      margin: 7,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 4,
    },
  },

  [`&.${classes.sectorContainer}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: 242,
    minWidth: 242,
    padding: 0,
    zIndex: 0,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
      justifyContent: "center",
      padding: "0px 20px",
    },
  },

  [`& .${classes.titleContainer}`]: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderBottom: "1px solid #540096",
    marginBottom: 15,
  },

  // hexagons
  [`& .${classes.hexagonContainer}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    pointerEvents: "none",
    backgroundColor: "white",
  },

  [`& .${classes.hexagonLeft}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    transform: "translate(-45%, 15%) scale(0.4) scaleY(-1)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-45%, -30%) scale(0.15) scaleY(-1)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(-45%, -20%) scale(0.2) scaleY(-1)",
    },
    [theme.breakpoints.between("md", "lg")]: {
      transform: "translate(-45%, 15%) scale(0.4) scaleY(-1)",
    },
    [theme.breakpoints.up("lg")]: {
      transform: "translate(-45%, 15%) scale(0.4) scaleY(-1)",
    },
  },

  [`& .${classes.hexagonRight}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 0,
    transform: "translate(55%, -20%) scale(0.4)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(50%, -30%) scale(0.15)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(50%, -20%) scale(0.2)",
    },
    [theme.breakpoints.between("md", "lg")]: {
      transform: "translate(55%, -25%) scale(0.4)",
    },
    [theme.breakpoints.up("lg")]: {
      transform: "translate(55%, -25%) scale(0.4)",
    },
  },

  [`& .${classes.hexagonBottomRight}`]: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 0,
    transform: "translate(55%, 20%) scale(0.4) scaleY(-1)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(53%, 30%) scale(0.2) scaleY(-1)",
    },
    [theme.breakpoints.between("md", "lg")]: {
      transform: "translate(60%, 30%) scale(0.4) scaleY(-1)",
    },
    [theme.breakpoints.up("lg")]: {
      transform: "translate(60%, 30%) scale(0.4) scaleY(-1)",
    },
  },

  [`& .${classes.titleAndDetails}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: 25,
    minWidth: 600,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
      marginLeft: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      minWidth: 450,
      marginLeft: 0,
    },
    [theme.breakpoints.between("md", "lg")]: {
      minWidth: 600,
    },
  },

  [`& .${classes.sectorParagraph}`]: {
    zIndex: 0,
    position: "relative",
    lineHeight: "18px",
    borderTop: "1px solid #540096",
    padding: "15px 15px 0px 15px",
    [theme.breakpoints.up("md")]: {
      lineHeight: "24px",
    },
  },

  [`& .${classes.videoContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "row",
    marginBottom: "5px",
  },

  [`& .${classes.arrowButtonContainer}`]: {
    borderRadius: "4px",
    margin: "0px 5px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF",
    },
  },
}));

const SectorListRoot = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  maxWidth: 242,
  minWidth: 242,
  padding: 0,
  zIndex: 0,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    minWidth: "100%",
    justifyContent: "center",
    padding: "0px 20px",
  },
  [`& .${classes.sectorButtonImage}`]: {
    width: 85,
    height: 85,
    [theme.breakpoints.down("md")]: {
      width: 55,
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },

  [`& .${classes.sectorButton}`]: {
    padding: 8,
    [theme.breakpoints.down("md")]: {
      padding: 6,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 3,
    },
  },

  [`& .${classes.sectorButtonContainer}`]: {
    margin: 10,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      margin: 7,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 4,
    },
  },

  [`& .${classes.sectorButtonContainerSelected}`]: {
    margin: 10,
    boxShadow: "0px 0px 33px -1px rgba(84,0,150,1)",
    borderRadius: "50%",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      margin: 7,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 4,
    },
  },
}));

const SectorButton = ({ selectedSector, setSelectedSector, sector, image }) => {
  let selected = sector?.title === selectedSector?.title;
  const gImage = getImage(image);

  return (
    <div
      className={
        selected
          ? classes.sectorButtonContainerSelected
          : classes.sectorButtonContainer
      }
      key={sector.title}
    >
      <Tooltip
        placement={"top"}
        title={sector.title}
        arrow
        sx={{
          tooltip: {
            backgroundColor: "#540096",
            color: "white",
            fontSize: 15,
            fontWeight: "600",
          },
          arrow: {
            color: "#540096",
          },
        }}
      >
        <ButtonBase
          className={classes.sectorButton}
          onClick={() => {
            setSelectedSector(sector);
          }}
          disableRipple
        >
          <GatsbyImage
            image={gImage}
            className={classes.sectorButtonImage}
            objectFit={"contain"}
            alt={`${sector.title} - Button`}
            width={85}
            height={85}
          />
        </ButtonBase>
      </Tooltip>
    </div>
  );
};

const SectorList = ({ selectedSector, setSelectedSector, images }) => {
  return (
    <SectorListRoot>
      <SectorButton
        sector={sectors[0]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.agriculture}
      />
      <SectorButton
        sector={sectors[1]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.industry}
      />
      <SectorButton
        sector={sectors[2]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.security}
      />
      <SectorButton
        sector={sectors[3]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.tunnels}
      />
      <SectorButton
        sector={sectors[4]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.construction}
      />
      <SectorButton
        sector={sectors[5]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.roads}
      />
      <SectorButton
        sector={sectors[6]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.smartDevices}
      />
      <SectorButton
        sector={sectors[7]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.sports}
      />
      <SectorButton
        sector={sectors[8]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.buildings}
      />
      <SectorButton
        sector={sectors[9]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.crowds}
      />
      <SectorButton
        sector={sectors[10]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.train}
      />
      <SectorButton
        sector={sectors[11]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.bridges}
      />
      <SectorButton
        sector={sectors[12]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.underground}
      />
      <SectorButton
        sector={sectors[13]}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        image={images.hospital}
      />
    </SectorListRoot>
  );
};

const SectorDetail = ({ sector, changeSector }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, []);

  const trail = useTrail(3, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: visible ? 1 : 0,
    y: visible ? 0 : 100,
    from: { opacity: 0, y: 100 },
  });

  const goBackSector = useCallback((sector) => {
    if (!sector) {
      return;
    }

    let scIdx = sectors.findIndex((s) => {
      return s.title === sector.title;
    });

    if (scIdx >= 0) {
      let newIndex = scIdx - 1;

      if (newIndex < 0) {
        newIndex = sectors.length - 1;
      }

      if (typeof changeSector === "function") {
        changeSector(sectors[newIndex]);
      }
    }
  }, []);

  const goForwardSector = useCallback((sector) => {
    if (!sector) {
      return;
    }

    let scIdx = sectors.findIndex((s) => {
      return s.title === sector.title;
    });

    if (scIdx >= 0) {
      let newIndex = (scIdx + 1) % sectors.length;

      if (typeof changeSector === "function") {
        changeSector(sectors[newIndex]);
      }
    }
  }, []);

  return (
    <div className={classes.detailContainer}>
      {trail.map((styles, index) => (
        <animated.div style={styles} key={`${sector.title}-${index}`}>
          {index === 0 && (
            <Typography variant={"h4"} className={classes.header}>
              {sector.title.toUpperCase()}
            </Typography>
          )}
          {index === 1 && (
            <div className={classes.videoContainer}>
              <IconButton
                size='large'
                className={classes.arrowButtonContainer}
                onClick={() => goBackSector(sector)}
              >
                <ArrowForwardIos
                  style={{
                    transform: "scale(-1)",
                  }}
                />
              </IconButton>
              <ReactPlayer
                url={sector.timelapse}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                style={{
                  background: "white",
                  margin: "0px 0px 7px 0px",
                }}
                width={"100%"}
              />
              <IconButton
                className={classes.arrowButtonContainer}
                onClick={() => goForwardSector(sector)}
              >
                <ArrowForwardIos />
              </IconButton>
            </div>
          )}
          {index === 2 && (
            <Typography className={classes.sectorParagraph}>
              {sector?.description || ""}
            </Typography>
          )}
        </animated.div>
      ))}
    </div>
  );
};

const SectorDetails = ({ sector, changeSector }) => {
  const [selectedSector, setSelectedSector] = useState(null);

  const transitions = useTransition(selectedSector, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    config: { duration: 500 },
  });

  useEffect(() => {
    if (sector) {
      setSelectedSector(sector);
    }
  }, [sector]);

  return (
    <div className={classes.detailContainer}>
      {selectedSector &&
        transitions((style, item) => (
          <animated.div
            style={{
              ...style,
            }}
          >
            {item && <SectorDetail sector={item} changeSector={changeSector} />}
          </animated.div>
        ))}
    </div>
  );
};

const SectorInformation = (props) => {
  const [selectedSector, setSelectedSector] = useState(null);

  const { ref, inView } = useInView({
    threshold: 0.05,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setSelectedSector(sectors[0]);
    }
  }, [inView]);

  return (
    <Root ref={ref}>
      <div className={classes.windowContainer}>
        <div className={classes.root}>
          <SectorList
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            images={props.images}
          />
          <div className={classes.titleAndDetails}>
            <div className={classes.titleContainer}>
              <Typography variant={"h3"}>UtterBerry Sectors</Typography>
            </div>
            {
              <SectorDetails
                sector={selectedSector}
                changeSector={setSelectedSector}
              />
            }
          </div>
        </div>
      </div>
      <div className={classes.hexagonContainer}>
        <StaticImage
          src={"../../images/utterberry-paints/hex-1.png"}
          className={classes.hexagonLeft}
          objectFit={"contain"}
          placeholder={"none"}
          alt={"Hex 1"}
        />
        <StaticImage
          src={"../../images/utterberry-paints/hex-2.png"}
          className={classes.hexagonRight}
          objectFit={"contain"}
          placeholder={"none"}
          alt={"Hex 2"}
        />
        <StaticImage
          src={"../../images/utterberry-paints/hex-3.png"}
          className={classes.hexagonBottomRight}
          objectFit={"contain"}
          placeholder={"none"}
          alt={"Hex 3"}
        />
      </div>
    </Root>
  );
};

export default SectorInformation;
