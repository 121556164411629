import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player/lazy";

import { useInView } from "react-intersection-observer";
import HomePageAnimatioVideo from "../../videos/utterberry-animation-480.mp4";

import WhiteBranchLeft from "../../images/branches/white-branch.svg";
import WhiteBranchRight from "../../images/branches/white-branch-2.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import { Suspense } from "react";

const PREFIX = "HomePageAnimation";

const classes = {
  windowContainer: `${PREFIX}-windowContainer`,
  root: `${PREFIX}-root`,
  reactPlayer: `${PREFIX}-reactPlayer`,
  branchLeft: `${PREFIX}-branchLeft`,
  branchRight: `${PREFIX}-branchRight`,
  mobile: `${PREFIX}-mobile`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  overflow: "hidden",
  position: "relative",
  zIndex: 1,
  background: "rgb(97, 1,161)",
  boxShadow: "0px 0px 50px 30px rgb(84 0 150 / 44%)",
  [`& .${classes.root}`]: {
    width: "100%",
    background: "rgb(97, 1,161)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
    padding: 5,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: 15,
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: 40,
    },
  },

  [`& .${classes.reactPlayer}`]: {
    maxWidth: 1000,
    width: "100%",
    height: "100%",
    background: "rgb(97, 1,161)",
  },

  [`& .${classes.mobile}`]: {
    background: "#540096",
  },

  [`& .${classes.branchLeft}`]: {
    position: "absolute",
    right: -7,
    top: 0,
    width: 200,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 120,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 140,
    },
  },

  [`& .${classes.branchRight}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    width: 198,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 119,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 138,
    },
    transform: "scaleX(-1)",
  },
}));

function isDeviceMobile() {
  let userAgent = "";
  if (typeof window !== "undefined") {
    userAgent = navigator.userAgent;
  }

  let isMobile;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    isMobile = false;
  } else if (userAgent.match(/firefox|fxios/i)) {
    isMobile = false;
  } else if (userAgent.match(/safari/i)) {
    isMobile = false;
  } else if (userAgent.match(/opr\//i)) {
    isMobile = false;
  } else if (userAgent.match(/edg/i)) {
    isMobile = false;
  } else if (userAgent.match(/android/i)) {
    isMobile = true;
  } else if (userAgent.match(/iphone/i)) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  return isMobile;
}

const HomePageAnimation = () => {
  const [play, setPlay] = useState(true);

  const [mounted, setMounted] = useState(false);
  const [include, setInclude] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.6,
  });

  useEffect(() => {
    if (mounted && inView) {
      setInclude(true);
    }

    setPlay(inView);
  }, [inView, mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Root>
      <img
        src={WhiteBranchLeft}
        className={classes.branchLeft}
        alt={"White Track Branch Left"}
      />
      <img
        src={WhiteBranchRight}
        className={classes.branchRight}
        alt={"White Track Branch Right"}
      />
      <div
        className={`${classes.root} ${isDeviceMobile() ? classes.mobile : ""}`}
        ref={ref}
      >
        {include && (
          <Suspense>
            <ReactPlayer
              url={HomePageAnimatioVideo}
              playing={play}
              className={`${classes.reactPlayer} ${
                isDeviceMobile() ? classes.mobile : ""
              }`}
              muted={true}
              playsinline={true}
              height={mobile ? 300 : medium ? 460 : 580}
              width={1000}
            />
          </Suspense>
        )}
      </div>
    </Root>
  );
};

export default HomePageAnimation;
